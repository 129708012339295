@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Lato', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #86BBBD;
  max-width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.deck{
  perspective: 1000px;
  position: absolute;
  left: 50%;
  top: 55%;
  width: 300px;
  height: 500px;
  transform: translate(-50%, -50%);
}

.deck .card{
  position: absolute;
}

.centerhorver{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.cassie-inner{
  height: 100%;
  width: 100%;
}

.cas-wave{
  height: 700px;
  width: 100%;
  position: absolute;
  bottom:0px;
  left:0px;
  background-position: center;
  background-size: 100%;
}


.fakeScreen{
  width: calc(100% - 100px);
  height: 100%;
  position: absolute;
  background-color: #86BBBD;
  background-size: 100%;
  background-position: center bottom;
  padding: 50px;
}

.welniet{
  position: fixed;
  width: calc(100% - 50px);
  line-height: 60px;
  left: 25px;
  top: 25px;
  font-size: 34px;
}
.thumbsup{
  float: left;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: white;
  background-image: url("./assets/thumbs-01.png");
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.thumbsdown{
  background-image: url("./assets/thumbs-02.png") !important;
}

.niet .thumbsup{
  float: right !important;
}
.welniet > div{
  float: left;
  width: 170px;
  height: 60px;
  background:red;
  border-radius: 30px;
  text-align: center;
  font-weight: 900;
  color: white;
}

.welniet{
  position: absolute;
}

.wel{
  background: #8DA7B9 !important;
}

.niet{
  background: #ACCBE1 !important;
  float: right !important;
}

.wavescreen{
  height: 100% !important;
  width: 100% !important;
  background-position:center bottom !important;
  position: absolute !important;
  background-size: 100% !important;
  left: 0px !important;
  bottom: 0px !important;
}


.arrow{
  font-size: 51px !important;
  position: absolute;
  margin-left: -10px;
  margin-top: -16px;
  background: #d77085;
  width: 70px;
  border-radius: 0px 15px 15px 0px;
}


.bigredbutton{
  background: #D67086;
  padding:20px;
  margin-left: -45px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 900;
  border-radius: 15px;
  width: 100%;
  color:white;
  text-decoration: none;
}

.bigredbuttontwo{
  background: #D67086;
  padding:20px;
  font-weight: 900;
  border-radius: 15px;
  width: 100%;
  color:white;
  text-decoration: none;
}

.bigredbuttonthree{
  background: #86bbbd;
  padding: 20px;
  font-weight: 900;
  bottom: 0;
  border-radius: 15px;
  color:white;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 276px;
}

.bigredbuttonfour{
  background: #53687e;
  padding:20px;
  font-weight: 900;
  top: 100%;
  bottom: 0px;
  border-radius: 15px;
  border: none;
  color:white;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 30px;
  width: 276px;
}

.bigredbuttonfive{
  background: #53687e;
  padding:20px;
  font-weight: 900;
  top: 100%;
  bottom: 0px;
  border-radius: 15px;
  border: none;
  color:white;
  text-decoration: none;
  text-align: center;
  margin-bottom: 15px;
  display: block;
  width: 100px;
}

.disabledfive{
  background: #bac3cb !important;
  pointer-events: none;
}

.herstartspellie {
  margin-top: 300px;
}

.herstartspellie .bigredbuttonfive{
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.bigredbuttonfour:disabled{
  opacity: 0.4;
  cursor: not-allowed;
}

.ww-1{
  background-image: url("./assets/ww/pensioen-wave-01.png");
}

.ww-2{
  background-image: url("./assets/ww/pensioen-wave-02.png");
}

.ww-3{
  background-image: url("./assets/ww/pensioen-wave-03.png");
}

.ww-4{
  background-image: url("./assets/ww/pensioen-wave-04.png");
}

.ww-5{
  background-image: url("./assets/ww/pensioen-wave-05.png");
}

.ww-6{
  background-image: url("./assets/ww/pensioen-wave-06.png");
}

.wave{
  background-image: url("./assets/wave-01.png");
  background-size: cover;
  background-position: center 30%;
  height: 100vh;
  width: 100%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}

.posrating{
  border-color: #94B447 !important;
}
.negrating{
  border-color: #BE2623 !important;
}

.posrating .result{
  background: #94B447;
}

.negrating .result{
  background: #BE2623;
}

.result{
  color:white;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
}


.results{
  font-size: 24px;
  color:white;
  position: fixed;
  border: 5px solid transparent;
  padding: 30px;
  background-image: url('./assets/ww/pensioen-wave-05.png');
  background-position: center;
  background-size: cover;
  text-align: center;
  width: calc(100% - 70px);
  height: calc(100% - 70px);
  overflow: scroll;
}

.statustitle{
  padding-top: 57.5px;
}

.fakecard{
  width: 300px;
  height: 500px;
  box-shadow: 13px 13px 13px -8px rgba(50, 50, 73, 0.3);
  /*box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);*/
  /*box-shadow: 0 12.5px 20px -10px rgba(50, 50, 73, 0.2), 0 10px 10px -10px rgba(50, 50, 73, 0.1);*/
  border-radius: 6px;
  background-color: #ebebeb;
  background-image: url("./assets/card/fakecard-01.png");
  z-index: 10;
  background-position: center;
  background-size: cover;
  position: absolute;
  transition: 0.5s ease all;
}


.card{
  transition: ease all 0.5s;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  width: 300px;
  height: 500px;
  /*box-shadow: 0 12.5px 20px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);*/
  /*background: #f4f4f4;*/
  z-index: 100;
}

.ontop{
  transform: rotate(0deg) !important;
  position: absolute;
  width: 400px;
  height: 600px;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 10000;
}

.cardfront{
  background-position: center;
  background-size: cover;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  transition: ease all 0.3s;
}
.text-top{
  height: 200px;
  width: 100%;
  position: relative;
}
.text-front{
  color:white;
  text-align: center !important;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  line-height: 1.3;
  font-size: 28px;
}
.ontop .text-front{
  font-size: 25px;
}
.cardbacktext{
  transition: ease all 0.3s;
  opacity: 0;
  height: calc(100% - 100px);
  width: calc(100% - 40px);
  margin-left: 20px;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  margin-top: 60px;
  overflow: hidden;
}
.turned .cardfront{
  z-index: -1;
}

.fakeScreen h1{
  font-weight: 900;
  font-size: 55px;
  line-height: 1 !important;
}

.fakeScreen div{
  position: fixed;
  bottom: 20px;
  left: 40px;
  color:white;
  font-size: 30px;
  width: calc(100% - 80px);
  line-height: 1.5;
  text-align: center !important;
  height: 40%;
}
.cardback{
  display: none;
  border-radius: 20px;
  position: absolute;
  height: 100%;
  background-image: url("./assets/card/KaartenHerontwerp_gestript-02.png");
  background-size: cover;
  background-position: center;
  transition: ease all 0.5s;
  transform: rotate3d(0, 1, 0, -180deg) !important;
  width: 100%;
}
.ontop .card{
  z-index: 1000;
}

.ontop{
  display: block !important;
}

.turned .cardback{
  z-index: 1010 !important;
}

.rotate .cardfront {
  transition: ease transform 0.5s;
  transform: rotate(0deg) rotate3d(0, 1, 0, 0deg) !important;

}

.rotateback .cardback {
  transition: ease transform 0.5s;
  transform: rotate(0deg) rotate3d(0, 1, 0, 0deg) !important;
}
.rotateback .cardbacktext {
  transition: ease transform 0.3s;
  transform: rotate(0deg) rotate3d(0, 1, 0, 0deg) !important;
}

.rotatez .cardfront{
  z-index: -1;
  display: none;
}

.rotatez .cardback{
  display: block !important;
}

.rotatez .cardbacktext{
  opacity: 1;
}



.showdealer{
  display: block !important;
}

.moveleft{
  position: absolute;
  transition: ease all 0.5s;
  margin-left: -500px;
  margin-top: 120px;
  transform: rotate(-20deg);
}

.rotated-cards .card{
  display: none;
}

.welniet-box{
  overflow: auto;
}

.welniet-title{
  margin-top: 20px;

}
.welniet-list{
  padding: 0px;
  list-style: none;
}

.welniet-list li{
  border-radius: 15px;
  width: calc(100% - 50px);
  padding: 10px;
  margin-left: 15px;
  margin-top: 20px;
  background: white;
}

.welniet-title div{
  width: 50%;
  float: left;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
}

.darkers .welniet-box{
  background: #8DA7B9;
}

.welniet-box{
  border-radius: 30px;
  top: 40px;
  position: fixed;
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 60px;
  height: calc(100% - 208px);
  background: #ACCBE1;
}

.resultbutton{
  position: fixed;
  width: 100vw !important;
  bottom: 25px;
  text-align: center;
  margin-left: 28px;
}

.moveright{
  position: absolute;
  transition: ease all 0.5s;
  margin-left: 500px;
  margin-top: 80px;
  transform: rotate(10deg);
}

.fadeout{
  opacity: 0;
}
.cardtitlething{
  font-size: 22px;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  display: block;
  width: calc(100% - 80px);
}
.welniet-list img{
  float: left;
}
.invis{
  display: none !important;
}

.loading{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -350px;
  transform: translate(-50%,-50%);
  font-weight: 900;
  color: white;
  font-size: 34px;
  text-align: center;
}


.loadingcardimage{
  background-image: url("./assets/card/loading.png");
  background-position: center;
  position: absolute;
  left: 50%;
  margin-left: -75px;
  margin-top: 250px;
  background-size: cover;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 150px;
  width: 150px;
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}


.loadingcard{
  perspective: 1000px;
  position: absolute;
  left: 50%;
  top: 55%;
  width: 300px;
  height: 500px;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #f4f4f4;
  background-image: url("./assets/card/fakecard-01.png");
  z-index: 10;
  background-position: center;
  background-size: cover;
  position: absolute;
}

.dealer{
  cursor: pointer;
  display: none;
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 100;
  left: calc(-50vw + 150px);
  top: calc(-50vh + 250px);
}
@media(max-width: 1000px){
  body{
    zoom: 0.8;
  }
}


.helpBubble{
  position: absolute;
  z-index: 1020;
  bottom: 0px;
  background-position: center;
  background-size: 25%;
  background-repeat: no-repeat;
  width: 140px;
  height: 80px;
  border-radius: 20px 20px 0px 0px;
  left: 50%;
  transform: translateX(-50%);
}

.cross{
  background-image: url("./assets/cross-sign.png");
  background-color: #53687E;
  transition: all 1s ease;
}

.questionmark{
  background-image: url("./assets/question-mark-draw.png");
  background-color: white;

}

.questfade{
  transition: all 1s ease;
}

.helpCard{
  position: fixed;
  bottom: 0px;
  z-index: 1010;
  background-color: white;
  width: calc(100% - 50px);
  transform: translateX(-50%);
  left: 50%;
  border-radius: 20px 20px 0px 0px;
  height: 50%;
  animation: movement 0.8s;
  animation-timing-function: ease;
}

.helpCardBackAnimation{
  animation: movementBack 0.8s !important;
}

.helpFrontAnimation{
  animation: movement 0.8s !important;
}

@keyframes movement {
  from {height: 0%}
  to {height: 50%}
}

@keyframes movementBack {
  from {height: 50%}
  to {height: 0%}
}

.helpCardSend{
  height: 467px !important;
}

.helpCardTitle{
  position: fixed;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  font-weight: 900;
  color: #53687E;
  font-size: 34px;
  text-align: center;
}

.guardrails{
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  position: fixed;
  background: black;
  opacity: 0.7;
  z-index: 900;
  animation: fadein 0.8s;
  animation-timing-function: ease;
}

.guardrailsout{
  animation: fadeout 1s;
  animation-timing-function: ease;
}

@keyframes fadein {
  from {opacity: 0}
  to {opacity: 0.7}
}

@keyframes fadeout {
  from {opacity: 0.7}
  to {opacity: 0}
}

.guardRailsAnimation{
  transition: all 1s ease;
}

.helpCardText{
  position: absolute;
  width: calc(100% - 25%);
  left: 50%;
  top: 75px;
  transform: translateX(-50%);
  color: #53687E;
  text-align: left;
  font-weight: 450;
  font-size: 20px;
}

.carouselHelpPicture{
  background-position: center;
  position: absolute;
  background-size: cover;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  margin-top: 25px;
}

.carouselHelp{
  transition: all 1s ease;
}

.carouselHelpAnimation{
  transition: all 1s ease;
}

.picture1{
  height: 150px !important;
}

.picture1 img, .picture2 img{
    margin: auto;
    position: relative;
    display: inherit;
}

.picture2{
    height: 150px !important;
}

.helpCard > div > div:last-child{
  bottom: 100px !important;
}

.helpCard > div > div:last-child > span{
  background: #53687e !important;
}

.helpCardAnimation > div > div:last-child > span{
  opacity: 0 !important;
}

.gegevensbox{
  position: absolute;
  background-color: white;
  width: 90%;
  height: 790px;
  border-radius: 20px 20px 20px 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
}

.datasent{
  height: 250px !important;
}

.gegevensboxtitle{
  margin-top: 10px;
  width: 100%;
  font-weight: 900;
  color: #53687e;
  font-size: 34px;
  text-align: center;
}

.gegevensuitleg{
  margin-top: 80px;
  margin-left: 35px;
  width: calc(100% - 70px);
  font-weight: 600;
  color: #53687e;
  font-size: 21px;
  text-align: left;
}

.voorwaardenButton{
  position: absolute;
  left: 50%;
  width: 100%;
  bottom: 0;
  transform: translateX(-50%);
}

.checkbox{
  width: calc(100% - 50px);
  position: relative;
  transition: 0.3s all;
  margin-left: 35px;
  border:0;
  margin-top: 10px;
  padding-top: 3px;
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  color: #53687e;
  font-size: 15px;

}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #53687e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.gegevensinput{
  width: calc(100% - 90px);
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  margin-left: 35px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  border-radius:10px;
  border:0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.gegevensinputhalf{
  width: calc(50% - 67px);
}

.algemenevoorwaarden{
  position: absolute;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: 70px;
  height: 500px;
  left: 50%;
  width: calc(90% - 45px);
  transform: translateX(-50%);
  font-size: 24px;
  text-align: left;
  overflow-y: scroll;
}

.algemenevoorwaardenbutton{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.formsup {
  width: 100%;
}

.car-wrapper div:first-of-type{
  position: absolute !important;
}

.newwaveblock{
  position: absolute;
  bottom: 0px;
}

.newwave2{
  width: 100%;
  max-height: 100%;
  position: absolute;
  bottom: 0px;
}

.wavetext{
  color: white;
  z-index: 10;
  position: absolute;
  width: calc(100% - 40px);
  left: 20px;
  bottom: 0px;
  height: 300px;
  text-align: center;
  line-height: 1.5;
  font-size: 32px;
}

.pillendame1{
  position: absolute;
  z-index: 10;
  top: 50%;
  margin-top: -20px;
  right: 20px;
  transform: translateY(-50%);
}

.welnietvoorbeeld1{
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.welnietvoorbeeld2{
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.pillenhoedje{
  position: absolute;
  z-index: 10;
  top: 66%;
  margin-top: -100px;
  left: 60px;
  transform: translateY(-50%);
}

.pillenman1{
  position: absolute;
  z-index: 10;
  top: 50%;
  margin-top: -40px;
  left: 20px;
  transform: translateY(-50%);
}

.bigredfix{
  margin-left: 0px !important;
  width: calc(100% - 40px) !important;
}

.wavetext h1{
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 52px;
}

.newwave2 img{
  z-index: -1;
  margin-bottom: -4px;
}

.welnietbutton{
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 900;
  border-radius: 15px;
  width: 150px;
  color:white;
  text-decoration: none;
}

.welnietbuttonwel{
  background: #accbe1;
}

.welnietbuttonniet{
  background: #8da7b9;
}

.welnietimage{
  border-style: solid;
  padding: 2px;
  border-radius: 50px;
}

.welnietimageniet{
  border-color: #8da7b9;
}

.welnietimagewel{
  border-color: #accbe1;
}

.welnietheader{
  position: relative;
}

.welnietbody{
  position: relative;
  margin-top: 25px;
}

.upswipevoorbeeld{
  position: absolute;
  z-index: 10;
  top: 44%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.invitewelcome{
  text-align: center;

  margin-top: 500px;
}
.invitewelcome h1{
  font-size: 42px;
}

.niettoegestaan{
  width: calc(100% - 40px);
  margin-left: 20px;
  text-align: center;
  position: absolute;
  line-height: 1.5;
  top: 50%;
  transform: translateY(-50%);
}

.niettoegestaan h1{
  font-size: 48px;
}

.invitewelcome p{
  font-size: 18px;
  width:100%;
  position: fixed;
  bottom: 0px;
  height: 100px;
  line-height: 1.5;
  left: 0px;
  color: #2f3b48;
}

.smartresizebox{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
  font-size: 18px;
  min-width: 200px;
}

.smartresizebox p{

}

.smartresizebox h1{
  padding: 0px;
  margin: 0px;
}

@media all and (max-width: 300px) {
  /**
   SpelUitleg
   */
  .pillendame1{
    width: 150px;
    top: 40%;
  }
  .pillenman1{
    width: 150px;
  }
  .pillenhoedje{
    width: 100px;
    top: 60%;
  }
  .welnietvoorbeeld1{
    width: 300px;
    top: 40%;
  }
  .upswipevoorbeeld{
    width: 125px;
    top: 35%;
  }

  .welnietvoorbeeld2{
    width: 350px;
  }

  .wavetext{
    bottom: 55px;
  }

  .hoedjetekst{
    bottom: 10px !important;
  }

  .bigredfix{
    font-size: 28px;
  }
}

@media all and (min-width:300px) and (max-width: 325px){
  .statustitle{
    padding-top: 0px !important;
  }

  .loading{
    margin-top: -400px !important;
  }
}

@media all and (min-width:300px) and (max-width: 375px){
  /**
   SpelUitleg
   */
  .pillendame1{
    width: 180px;
    top: 40%;
  }
  .pillenman1{
    width: 180px;
  }
  .pillenhoedje{
    width: 100px;
    top: 60%;
  }
  .welnietvoorbeeld1{
    width: 350px;
    top: 42%;
  }
  .upswipevoorbeeld{
    width: 150px;
    top: 35%;
  }

  .welnietvoorbeeld2{
    width: 400px;
  }

  .dubbelepillen{
    width: 150px;
  }

  /**
    Laadscherm
   */
  .loading{
    top: 65%;
    font-size: 28px;
    margin-top: -450px;
  }

  .loadingcardimage{
    bottom: 22%;
    left: 59%;
    height: 100px;
    width: 100px;
  }

  .loadingcard{
    width: 250px;
    height: 400px;
  }

  /**
    Kaartspel
   */
  .fakecard{
    width: 260px;
    height: 445px;
    left: 20px;
  }

  .card{
    width: 260px;
    height: 445px;
    left: 20px;
  }

  .text-top{
    height: 200px;
    width: 100%;
    position: relative;
  }
  .text-front{
    font-size: 22px;
  }

  .helpBubble{
    width: 125px;
    height: 75px;
  }

  .helpCard{
    width: calc(100% - 75px);
    left: 50%;
    height: 58%;
  }

  @keyframes movement {
    from {height: 0%}
    to {height: 58%}
  }

  @keyframes movementBack {
    from {height: 58%}
    to {height: 0%}
  }

  /**
    uitslag
   */
  .results{
    font-size: 19px;
  }

  .statustitle{
    padding-top: 65px;
  }

}

@media all and (min-width:350px) and (max-width: 890px) {
  .text-front{
    font-size: 23px;
    width: calc(100% - 20px);
  }
}

@media all and (min-width:550px) and (max-width: 1100px) {
  /**
   SpelUitleg
   */
  .pillendame1{
    width: 300px;
    right: 80px;
    top: 53%;
  }
  .pillenman1{
    width: 300px;
    left: 80px;
    top: 53%;
  }
  .pillenhoedje{
    width: 180px;
    top: 69%;
    left: 100px;
  }
  .welnietvoorbeeld1{
    width: 575px;
    top: 52%;

  }
  .upswipevoorbeeld{
    width: 275px;
  }

  .welnietvoorbeeld2{
    width: 650px;
  }

  .wavetext{
    font-size: 50px;
    bottom: 25px;
  }

  .lastwave{
    bottom: 100px;
  }

  /**
    Laadscherm
   */
  .loading{
    top: 40%;
    font-size: 50px;
  }

  .loadingcardimage{
    bottom: 22%;
    left: 43%;
    height: 200px;
    width: 200px;
  }

  .loadingcard{
    width: 425px;
    height: 650px;
  }

  /**
    Kaartspel
   */
  .fakecard{
    width: 400px;
    height: 650px;
    left: -45px;
    bottom: -50px;
  }

  .card{
    width: 400px;
    height: 650px;
    left: -45px;
    bottom: -50px;
  }

  .text-top{
    height: 150px;
    width: 100%;
    position: relative;
    text-align: center;
  }
  .text-front{
    bottom: -25px;
    left: 51%;
    font-size: 30px;
    text-align: center;
  }

  .helpBubble{
    width: 200px;
    height: 125px;
  }

  .helpCard{
    width: calc(100% - 200px);
    left: 50%;
    height: 45%;
  }

  .carouselHelpPicture{
    background-position: center;
    position: absolute;
    background-size: contain;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin-top: 25px;
  }

  .helpCardTitle{
    font-size: 45px;
  }

  .helpCardText{
    left: 50%;
    top: 100px;
    font-size: 30px;
  }

  @keyframes movement {
    from {height: 0%}
    to {height: 45%}
  }

  @keyframes movementBack {
    from {height: 45%}
    to {height: 0%}
  }

  .welniet > div{
    float: left;
    width: 300px;
    height: 85px;
    background:red;
    border-radius: 50px;
    text-align: center;
    font-weight: 900;
    color: white;
  }

  .wel{
    margin: auto;
    width: 50%;
    font-size: 45px;
    line-height: 80px;
  }

  .niet{
    margin: auto;
    width: 50%;
    font-size: 45px;
    line-height: 80px;
  }

  .welniet{
    position: fixed;
    width: calc(100% - 50px);
    line-height: 60px;
    left: 25px;
    top: 25px;
    font-size: 34px;
  }

  .thumbsup{
    height: 85px;
    width: 85px;
    border-radius: 50px;
  }

  /**
  Wel niet
   */
  .welniet-box{
    border-radius: 30px;
    top: 40px;
    position: fixed;
    width: calc(100% - 60px);
    margin: 30px;
    margin-top: 90px;
    height: calc(100% - 240px);
    background: #ACCBE1;
  }

  .resultbutton{
    position: fixed;
    width: calc(100% - 100px) !important;
    bottom: 25px;
    text-align: center;
    margin-left: 28px;
  }

  .welnietbody > b{
    font-size: 18px;
  }

  .welnietbody > p{
    font-size: 18px;
  }

  /**
    uitslag
   */
  .results{
    font-size: 30px;
  }

  .statustitle{
    padding-top: 190px;
  }
}

@media all and (min-width: 1100px) {
  /**
   SpelUitleg
   */
  .pillendame1{
    width: 300px;
    right: 125px;
    top: 40%;
  }
  .pillenman1{
    width: 250px;
    left: 80px;
  }
  .pillenhoedje{
    width: 150px;
    top: 60%;
    left: 125px;
  }
  .welnietvoorbeeld1{
    width: 500px;
    top: 40%;

  }
  .upswipevoorbeeld{
    width: 225px;
    top: 30%
  }

  .welnietvoorbeeld2{
    width: 500px;
  }

  .wavetext{
    font-size: 30px;
  }

  .dubbelepillen{
    width: 250px;
  }

  /**
    Laadscherm
   */
  .loading{
    top: 55%;
    font-size: 40px;
    margin-top: -430px;
  }


  .loadingcardimage{
    bottom: 22%;
    left: 53%;
    height: 125px;
    width: 125px;
  }

  .loadingcard{
    width: 300px;
    height: 450px;
  }

  /**
    Kaartspel
   */
  .fakecard{
    width: 350px;
    height: 550px;
    bottom: 50px;
    right: -30px;
  }

  .card{
    width: 350px;
    height: 550px;
    bottom: 50px;
    right: -30px;
  }

  .helpBubble{
    width: 150px;
    height: 85px;
  }

  .helpCard{
    width: calc(100% - 1050px);
    left: 50%;
    height: 62%;
  }

  .carouselHelpPicture{
    background-position: center;
    position: absolute;
    background-size: contain;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin-top: 25px;
  }

  .helpCardTitle{
    font-size: 32px;
  }

  .helpCardText{
    left: 50%;
    top: 100px;
    font-size: 24px;
  }

  @keyframes movement {
    from {height: 0%}
    to {height: 62%}
  }

  @keyframes movementBack {
    from {height: 62%}
    to {height: 0%}
  }

  .welniet > div{
    float: left;
    width: 200px;
    height: 75px;
    background:red;
    border-radius: 50px;
    text-align: center;
    font-weight: 900;
    color: white;
  }

  .wel{
    margin: auto;
    width: 50%;
    font-size: 32px;
    line-height: 70px;
  }

  .niet{
    margin: auto;
    width: 50%;
    font-size: 32px;
    line-height: 70px;
  }

  .welniet{
    position: fixed;
    width: calc(100% - 800px);
    line-height: 60px;
    left: 400px;
    top: 35px;
    font-size: 34px;
  }

  .thumbsup{
    height: 75px;
    width: 75px;
    border-radius: 50px;
  }

  /**
  Wel niet
   */
  .welniet-box{
    border-radius: 30px;
    top: 40px;
    left: 19%;
    position: fixed;
    width: calc(100% - 790px);
    margin: 30px;
    margin-top: 90px;
    height: calc(100% - 230px);
    background: #ACCBE1;
  }

  .resultbutton{
    position: fixed;
    left: 19%;
    width: calc(100% - 830px) !important;
    bottom: 25px;
    text-align: center;
    margin-left: 28px;
  }

  /**
    uitslag
   */
  .results{
    overflow: hidden;
  }

  .result{
    color:white;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    width: calc(780px);
    left: 50%;
    margin-left: -390px; /* Negative half of width. */
  }

  /**
    verzending
   */
  .gegevensbox{
    width: 50%;
  }

  .invitewelcome p{
    height: 75px;
  }
}

.loadingcardanim{
  transition: ease all 0.7s;
}

.location{
  margin-top: -1000px !important;
  transition: ease all 0.3s;
}

.rotation{
  transition: 0.5s ease all;
  transform: rotate(0deg)!important;
}

.poweredby{
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 18px;
  transform: translate(-50%,-50%);
  width: 360px;
}

.pasveerlogo{
  float: right;
}

.offsetbutton{
    cursor: pointer;
    width: 300px;
    margin-top: 55px;
    margin-left: calc(50% - 170px);
}

.wrapper-box1{
    position: relative;
    height: 650px;
}

.langswitch{
    text-align: center;
    z-index: 10000;
    cursor: pointer;

    top: 10px;
    font-size: 20px;
    height: 50px;
    color:white;
}

.langswitch .bigredbutton{
    position: fixed;
    left:50%;
    top: 10px;
    margin-left: -170px;
    width: 300px;
}